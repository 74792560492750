import { IChatPrompt } from "../models/ChatPrompt";
import { ICreateEditChatPrompt } from "../models/CreateEditChatPrompt";
import { BaseService } from "./BaseService";

export class PromptService extends BaseService {
    public getAllPromptsAsync = async (accessToken: string): Promise<IChatPrompt[]> => {
        const result = await this.getResponseAsync<IChatPrompt[]>(
            {
                commandPath: 'chatUserPrompt',
                method: 'GET',
            },
            accessToken,
        );
        return result;
    }

    public getPromptAsync = async (id: string, accessToken: string): Promise<IChatPrompt> => {
        const result = await this.getResponseAsync<IChatPrompt>(
            {
                commandPath: `chatUserPrompt/${id}`,
                method: 'GET',
            },
            accessToken,
        );
        return result;
    }

    public createPromptAsync = async (prompt: ICreateEditChatPrompt, accessToken: string): Promise<void> => {
        await this.getResponseAsync<unknown>(
            {
                commandPath: 'chatUserPrompt',
                method: 'POST',
                body: prompt,
            },
            accessToken,
        );
    }

    public updatePromptAsync = async (prompt: ICreateEditChatPrompt, accessToken: string): Promise<void> => {
        await this.getResponseAsync<unknown>(
            {
                commandPath: `chatUserPrompt/${prompt.id}`,
                method: 'PATCH',
                body: prompt,
            },
            accessToken,
        );
    }

    public deletePromptAsync = async (id: string, accessToken: string): Promise<void> => {
        await this.getResponseAsync<unknown>(
            {
                commandPath: `chatUserPrompt/${id}`,
                method: 'DELETE',
            },
            accessToken,
        );
    }

    public getPersonalPromptsAsync = async (accessToken: string): Promise<IChatPrompt[]> => {
        const result = await this.getResponseAsync<IChatPrompt[]>(
            {
                commandPath: 'chatUserPrompt/personal',
                method: 'GET',
            },
            accessToken,
        );
        return result;
    }

    public isUserPromptAdminAsync = async (accessToken: string): Promise<boolean> => {
        const result = await this.getResponseAsync<boolean>(
            {
                commandPath: `chatUserPrompt/isAdmin`,
                method: 'GET',
            },
            accessToken,
        );
        return result;
    }
}
