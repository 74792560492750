import React, { useState, useCallback } from 'react';
import {
    Button,
    Dialog,
    DialogTrigger,
    DialogSurface,
    DialogBody,
    DialogTitle,
    DialogContent,
    DialogActions,
    Spinner,
    makeStyles,
    shorthands
} from "@fluentui/react-components";
import { ArrowExportUpRegular, DocumentPdfRegular, DocumentTextRegular } from "@fluentui/react-icons";
import { useWorkflow } from '../../libs/hooks/useWorkflow';

const useStyles = makeStyles({
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.gap('16px'),
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        ...shorthands.gap('16px'),
    },
    exportButton: {
        minWidth: '100px',
        height: '36px',
    },
});

interface ExportOutputsProps {
    workflowInstanceId: string;
}

export const ExportOutputs: React.FC<ExportOutputsProps> = ({workflowInstanceId}) => {
    const styles = useStyles();
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const workflowHook = useWorkflow();

    const exportOutputs = useCallback(async (fileType: 'pdf' | 'docx') => {
        setIsLoading(true);
        try {
            let content;
            let mimeType;
            if (fileType === 'pdf') {
                content = await workflowHook.getWorkflowDataExportPDF(workflowInstanceId);
                mimeType = "application/pdf";
            } else {
                content = await workflowHook.getWorkflowDataExportDOCX(workflowInstanceId);
                mimeType = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
            }

            const blob = base64ToBlob(content.base64Payload, mimeType);
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `${content.filename}`;
            link.click();
            URL.revokeObjectURL(url);

        } catch (error) {
            console.error('Error exporting outputs:', error);
        } finally {
            setIsLoading(false);
            setIsDialogOpen(false);
        }
    }, [workflowInstanceId, workflowHook]);

    const base64ToBlob = (base64: string, type: string) => {
        const binStr = atob(base64);
        const len = binStr.length;
        const arr = new Uint8Array(len);

        for (let i = 0; i < len; i++) {
            arr[i] = binStr.charCodeAt(i);
        }

        return new Blob([arr], {type});
    };

    const handleExportPDF = useCallback(() => {
        void exportOutputs('pdf');
    }, [exportOutputs]);

    const handleExportDOCX = useCallback(() => {
        void exportOutputs('docx');
    }, [exportOutputs]);

    return (
        <>
            <Dialog open={isDialogOpen} onOpenChange={(_event, data) => {
                setIsDialogOpen(data.open)
            }}>
                <DialogTrigger disableButtonEnhancement>
                    <Button icon={<ArrowExportUpRegular/>} onClick={() => {
                        setIsDialogOpen(true)
                    }}>Export Outputs</Button>
                </DialogTrigger>
                <DialogSurface>
                    <DialogBody>
                        <DialogTitle>Choose Export Format</DialogTitle>
                        <DialogContent className={styles.dialogContent}>
                            {isLoading ? (
                                <Spinner label="Exporting..."/>
                            ) : (
                                <>
                                    <span>Select the file format for exporting outputs:</span>
                                    <div className={styles.buttonContainer}>
                                        <Button
                                            icon={<DocumentPdfRegular/>}
                                            appearance="primary"
                                            onClick={handleExportPDF}
                                            className={styles.exportButton}
                                        >
                                            PDF
                                        </Button>
                                        <Button
                                            icon={<DocumentTextRegular/>}
                                            appearance="primary"
                                            onClick={handleExportDOCX}
                                            className={styles.exportButton}
                                        >
                                            DOCX
                                        </Button>
                                    </div>
                                </>
                            )}
                        </DialogContent>
                        <DialogActions>
                            <DialogTrigger disableButtonEnhancement>
                                <Button appearance="secondary">Cancel</Button>
                            </DialogTrigger>
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        </>
    );
};
