import { IChangeUsersRequest } from "../models/Workflows/ChangeUsersRequest";
import { IUserRequestNewWorkflowInstance } from "../models/Workflows/UserRequestNewWorkflowInstance";
import { IUserRequestWorkflowInstanceCompareFiles } from "../models/Workflows/UserRequestWorkflowInstanceCompareFiles";
import { IUserRequestWorkflowInstanceFile } from "../models/Workflows/UserRequestWorkflowInstanceFile";
import { IUserRequestWorkflowInstanceText } from "../models/Workflows/UserRequestWorkflowInstanceText";
import { UserStepDataExport } from "../models/Workflows/UserStepDataExport";
import { IUserWorkflow } from "../models/Workflows/UserWorkflow";
import { IUserWorkflowDataItemCollection } from "../models/Workflows/UserWorkflowDataItemCollection";
import { IUserWorkflowInstance } from "../models/Workflows/UserWorkflowInstance";
import { WorkflowInstanceStepItemState } from "../models/Workflows/WorkflowInstanceStepItemState";
import { BaseService } from "./BaseService";

export class WorkflowService extends BaseService {
    public getWorkflowsByUser = async (accessToken: string): Promise<IUserWorkflow[]> => {
        const result = await this.getResponseAsync<IUserWorkflow[]>(
            {
                commandPath: 'workflows/user',
                method: 'GET',
            },
            accessToken,
        );
        return result;
    }

    public getWorkflowInstancesByUser = async (accessToken: string): Promise<IUserWorkflowInstance[]> => {
        const result = await this.getResponseAsync<IUserWorkflowInstance[]>(
            {
                commandPath: 'workflows/instances/user',
                method: 'GET',
            },
            accessToken,
        );
        return result;
    }

    public getWorkflowInstanceStepItemState = async (accessToken: string, workflowStepId: string, workflowStepDataItemId: string): Promise<WorkflowInstanceStepItemState> => {
        const result = await this.getResponseAsync<WorkflowInstanceStepItemState>(
            {
                commandPath: `workflows/step/${workflowStepId}/stepitem/state/${workflowStepDataItemId}`,
                method: 'GET',
            },
            accessToken,
        );
        return result;
    }

    public addWorkflowInstance = async (accessToken: string, body: IUserRequestNewWorkflowInstance): Promise<IUserWorkflowInstance> => {
        const result = await this.getResponseAsync<IUserWorkflowInstance>(
            {
                commandPath: 'workflows/instances',
                method: 'POST',
                body,
            },
            accessToken,
        );
        return result;
    }

    public addInputUserRequestWorkflowInstanceCompareFiles = async (accessToken: string, body: IUserRequestWorkflowInstanceCompareFiles): Promise<void> => {
        await this.getResponseAsync<any>(
            {
                commandPath: 'workflows/instances/comparefiles',
                method: 'POST',
                body,
            },
            accessToken,
        );
    }

    public addInputUserRequestWorkflowInstanceFile = async (accessToken: string, body: IUserRequestWorkflowInstanceFile): Promise<void> => {
        await this.getResponseAsync<any>(
            {
                commandPath: 'workflows/instances/file',
                method: 'POST',
                body,
            },
            accessToken,
        );
    }

    public addInputUserRequestWorkflowInstanceFiles = async (accessToken: string, body: FormData): Promise<void> => {
        await this.getResponseAsync<any>(
            {
                commandPath: 'workflows/instances/files',
                method: 'POST',
                body,
            },
            accessToken,
        );
    }

    public addInputUserRequestWorkflowInstanceText = async (accessToken: string, body: IUserRequestWorkflowInstanceText): Promise<void> => {
        await this.getResponseAsync<any>(
            {
                commandPath: 'workflows/instances/text',
                method: 'POST',
                body,
            },
            accessToken,
        );
    }

    public getWorkflowData = async (accessToken: string, workflowInstanceId: string, dataDirection: string): Promise<IUserWorkflowDataItemCollection> => {
        const result = await this.getResponseAsync<IUserWorkflowDataItemCollection>(
            {
                commandPath: `workflows/instances/${workflowInstanceId}/data/${dataDirection}`,
                method: 'GET',
            },
            accessToken,
        );
        return result;
    }

    public getWorkflowStepData = async (accessToken: string, workflowInstanceId: string, workflowStepId: string, dataDirection: string): Promise<IUserWorkflowDataItemCollection> => {
        const result = await this.getResponseAsync<IUserWorkflowDataItemCollection>(
            {
                commandPath: `workflows/instances/${workflowInstanceId}/step/${workflowStepId}/data/${dataDirection}`,
                method: 'GET',
            },
            accessToken,
        );
        return result;
    }

    public getWorkflowDataExportPDF = async (accessToken: string, workflowInstanceId: string): Promise<UserStepDataExport> => {
        const result = await this.getResponseAsync<UserStepDataExport>(
            {
                commandPath: `workflows/instances/${workflowInstanceId}/data/export/pdf`,
                method: 'GET',
            },
            accessToken,
        );
        return result;
    }

    public getWorkflowDataExportDOCX = async (accessToken: string, workflowInstanceId: string): Promise<UserStepDataExport> => {
        const result = await this.getResponseAsync<UserStepDataExport>(
            {
                commandPath: `workflows/instances/${workflowInstanceId}/data/export/docx`,
                method: 'GET',
            },
            accessToken,
        );
        return result;
    }

    public updateUsers = async (accessToken: string, body: IChangeUsersRequest): Promise<void> => {
        await this.getResponseAsync<any>(
            {
                commandPath: `workflows/instances/${body.workflowInstanceId}`,
                method: 'POST',
                body,
            },
            accessToken,
        );
    }
}
