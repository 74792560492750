export interface IUserWorkflowInstance {
    workflowId: string;
    workflowInstanceId: string;
    name: string;
    description: string;
    instanceName: string;
    workflowVersion: string;
    versionDate: string;
    icon: string;
    userInput: Record<string, string>;
    workflowInstanceState: WorkflowInstanceState;
    workflowInstanceCreated: string;
    workflowInstanceCompleted?: string;
    userWorkflowInstanceUsers: IUserWorkflowInstanceUser[];
}

export enum WorkflowInstanceState {
    COMPLETE = "COMPLETE",
    CREATED = "CREATED",
    DELETING = "DELETING",
    ERRORED = "ERRORED",
    MARKEDFORDELETION = "MARKEDFORDELETION",
    PROCESSING = "PROCESSING",
    READY = "READY",
    READYFORPROCESSING = "READYFORPROCESSING",
    UPDATING = "UPDATING",
}

export interface IUserWorkflowInstanceUser {
    userId: string;
    userName: string;
    displayName: string;
    firstName: string;
    lastName: string;
    preferredName: string;
    email: string;
}
