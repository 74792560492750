import {
    useToastController,
    Toast,
    ToastTitle,
    ToastBody
} from '@fluentui/react-components';
import React from 'react';

export const useCustomToast = () => {
    const {dispatchToast} = useToastController();

    const showToast = (title: string, bodyLines: string[], intent?: 'success' | 'error' | 'warning' | 'info') => {
        dispatchToast(
            <Toast>
                <ToastTitle>{title}</ToastTitle>
                <ToastBody>
                    {bodyLines.map((line, index) => (
                        <React.Fragment key={index}>
                            {line}
                            {index < bodyLines.length - 1 && <br />}
                        </React.Fragment>
                    ))}
                </ToastBody>
            </Toast>,
            { intent }
        );
    };

    return {showToast};
};
