import { Button, Input, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { usePrompts } from "../../../libs/hooks/usePrompts";
import { SearchRegular } from "@fluentui/react-icons";
import { useEffect, useState } from "react";
import { IChatPrompt } from "../../../libs/models/ChatPrompt";
import { PromptAdminCard } from "./PromptAdminCard";
import { EditPromptDialog } from "./EditPromptDialog";
import { AddPromptDialog } from "./AddPromptDialog";

const useStyles = makeStyles({
    root: {
        ...shorthands.overflow('hidden'),
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: tokens.colorNeutralBackground3,
        ...shorthands.margin('24px'),
    },
    searchRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    prompts: {
        ...shorthands.gap("36px"),
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        marginBottom: "8px",
        marginTop: "24px"
    }
});


export const PromptAdmin = () => {
    const styles = useStyles();
    const promptsHook = usePrompts();
    const [prompts, setPrompts] = useState<IChatPrompt[]>([]);
    const [isNewPromptDialogOpen, setIsNewPromptDialogOpen] = useState(false);
    const [isEditPromptsDialogOpen, setIsEditPromptsDialogOpen] = useState(false);
    const [selectedPrompt, setSelectedPrompt] = useState(prompts[0]);
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        promptsHook.getAllPrompts().then((result) => {
            setPrompts(result);
        }).catch((error) => {
            console.error("Failed to fetch prompts:", error);
        });
    }, [refresh]);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(event);
        // TODO: Implement search
    }

    const onCardClick = (id: string) => {
        const prompt = prompts.find((prompt) => prompt.id === id);
        if (!prompt) {
            console.error("Failed to find prompt with id:", id);
            return;
        }
        setSelectedPrompt(prompt);
        setIsEditPromptsDialogOpen(true);
    }

    return (
        <div className={styles.root}>
            <div className={styles.searchRow}>
                <Input contentBefore={<SearchRegular/>} onChange={handleSearchChange}/>
                <Button appearance={"primary"} onClick={() => {setIsNewPromptDialogOpen(true)}}>New Prompt</Button>
            </div>
            <div className={styles.prompts}>
                {prompts.map((prompt) => (
                    <PromptAdminCard key={prompt.id} id={prompt.id} title={prompt.name} description={prompt.description} onCardClick={onCardClick}/>
                ))}
            </div>
            <EditPromptDialog
                open={isEditPromptsDialogOpen}
                onCloseDialog={() => {setIsEditPromptsDialogOpen(false)}}
                prompt={selectedPrompt}
                onRefresh={() => {setRefresh(!refresh)}}
            />
            <AddPromptDialog
                open={isNewPromptDialogOpen}
                onCloseDialog={() => {setIsNewPromptDialogOpen(false)}}
                onRefresh={() => {setRefresh(!refresh)}}
                promptType="GLOBAL"
            />
        </div>
    )
}
