import { useMsal } from "@azure/msal-react";
import { AuthHelper } from '../auth/AuthHelper';
import { WorkflowService } from "../services/WorkflowService";
import { IUserWorkflow } from "../models/Workflows/UserWorkflow";
import { IUserRequestWorkflowInstanceFile } from "../models/Workflows/UserRequestWorkflowInstanceFile";
import { IUserRequestWorkflowInstanceFiles } from "../models/Workflows/UserRequestWorkflowInstanceFiles";
import { IUserRequestWorkflowInstanceText } from "../models/Workflows/UserRequestWorkflowInstanceText";
import { IUserRequestNewWorkflowInstance } from "../models/Workflows/UserRequestNewWorkflowInstance";
import { IUserRequestWorkflowInstanceCompareFiles } from "../models/Workflows/UserRequestWorkflowInstanceCompareFiles";
import { IUserWorkflowInstance } from "../models/Workflows/UserWorkflowInstance";
import { IChangeUsersRequestUser } from "../models/Workflows/ChangeUsersRequest";

export const useWorkflow = () => {
    const workflowService = new WorkflowService();
    const { instance, inProgress } = useMsal();

    const getWorkflowsByUser = async () => {
        let workflows: IUserWorkflow[] = [];
        try {
            workflows = await workflowService.getWorkflowsByUser(await AuthHelper.getSKaaSAccessToken(instance, inProgress));
        } catch (error) {
            console.error(error);
            throw error;
        }
        return workflows;
    }

    const getWorkflowInstancesByUser = async () => {
        let instances: IUserWorkflowInstance[] = [];
        try {
            instances = await workflowService.getWorkflowInstancesByUser(await AuthHelper.getSKaaSAccessToken(instance, inProgress));
        } catch (error) {
            console.error(error);
            throw error;
        }
        return instances;
    }

    const getWorkflowInstanceStepItemState = async (workflowStepId: string, workflowStepDataItemId: string) => {
        try {
            return await workflowService.getWorkflowInstanceStepItemState(await AuthHelper.getSKaaSAccessToken(instance, inProgress), workflowStepId, workflowStepDataItemId);
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    const addWorkflowInstance = async (body: IUserRequestNewWorkflowInstance) => {
        try {
            return await workflowService.addWorkflowInstance(await AuthHelper.getSKaaSAccessToken(instance, inProgress), body);
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    const addInputUserRequestWorkflowInstanceCompareFiles = async (body: IUserRequestWorkflowInstanceCompareFiles) => {
        try {
            await workflowService.addInputUserRequestWorkflowInstanceCompareFiles(await AuthHelper.getSKaaSAccessToken(instance, inProgress), body);
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    const addInputUserRequestWorkflowInstanceFile = async (body: IUserRequestWorkflowInstanceFile) => {
        try {
            await workflowService.addInputUserRequestWorkflowInstanceFile(await AuthHelper.getSKaaSAccessToken(instance, inProgress), body);
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    const addInputUserRequestWorkflowInstanceFiles = async (body: IUserRequestWorkflowInstanceFiles) => {
        const formData = new FormData();
        formData.append('workflowInstanceId', body.workflowInstanceId);
        body.workflowInstanceFiles.forEach((file, _index) => {
            formData.append(`WorkflowInstanceFiles`, file, file.name);
        });

        try {
            await workflowService.addInputUserRequestWorkflowInstanceFiles(await AuthHelper.getSKaaSAccessToken(instance, inProgress), formData);
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    const addInputUserRequestWorkflowInstanceText = async (body: IUserRequestWorkflowInstanceText) => {
        try {
            await workflowService.addInputUserRequestWorkflowInstanceText(await AuthHelper.getSKaaSAccessToken(instance, inProgress), body);
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    const getWorkflowData = async (workflowInstanceId: string, dataDirection: string) => {
        try {
            return await workflowService.getWorkflowData(await AuthHelper.getSKaaSAccessToken(instance, inProgress), workflowInstanceId, dataDirection);
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    const getWorkflowStepData = async (workflowInstanceId: string, workflowStepId: string, dataDirection: string) => {
        try {
            return await workflowService.getWorkflowStepData(await AuthHelper.getSKaaSAccessToken(instance, inProgress), workflowInstanceId, workflowStepId, dataDirection);
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    const getWorkflowDataExportPDF = async (workflowInstanceId: string) => {
        try {
            return await workflowService.getWorkflowDataExportPDF(await AuthHelper.getSKaaSAccessToken(instance, inProgress), workflowInstanceId);
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    const getWorkflowDataExportDOCX = async (workflowInstanceId: string) => {
        try {
            return await workflowService.getWorkflowDataExportDOCX(await AuthHelper.getSKaaSAccessToken(instance, inProgress), workflowInstanceId);
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    const updateUsers = async (workflowInstanceId: string, users: IChangeUsersRequestUser[]) => {
        try {
            await workflowService.updateUsers(await AuthHelper.getSKaaSAccessToken(instance, inProgress), { workflowInstanceId, users });
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    return {
        getWorkflowsByUser,
        getWorkflowInstancesByUser,
        getWorkflowInstanceStepItemState,
        addWorkflowInstance,
        addInputUserRequestWorkflowInstanceCompareFiles,
        addInputUserRequestWorkflowInstanceFile,
        addInputUserRequestWorkflowInstanceFiles,
        addInputUserRequestWorkflowInstanceText,
        getWorkflowData,
        getWorkflowStepData,
        getWorkflowDataExportPDF,
        getWorkflowDataExportDOCX,
        updateUsers,
    }
}
