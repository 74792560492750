import { Text, Caption1, Card, CardHeader, shorthands, makeStyles, tokens, Button } from "@fluentui/react-components";
import { useDispatch } from "react-redux";
import { setConversationInput, setPrompt } from "../../../redux/features/conversations/conversationsSlice";
import { useAppSelector } from "../../../redux/app/hooks";
import { RootState } from "../../../redux/app/store";
import { setSelectedChatWindowTab } from "../../../redux/features/app/appSlice";
import { MoreHorizontal20Regular } from '@fluentui/react-icons';

const useStyles = makeStyles({
    card: {
        width: "360px",
        maxWidth: "100%",
        minHeight: "120px",
        height: "auto",
    },
    caption: {
        color: tokens.colorNeutralForeground3,
    },
    text: {
        ...shorthands.margin(0),
    },
});

interface PromptCardProps {
    id: string;
    title: string;
    description: string;
    prompt: string;
    persona: string | undefined;
    tags: string[] | undefined;
    isPersonal: boolean; // New prop to determine if the prompt is personal
    onEdit: () => void; // New prop for handling edit action
}

export const PromptCard: React.FC<PromptCardProps> = ({id, title, description, prompt, tags, isPersonal, onEdit}) => {
    const styles = useStyles();
    const dispatch = useDispatch();
    const {selectedId} = useAppSelector((state: RootState) => state.conversations);

    const onClick = () => {
        dispatch(setConversationInput({id: selectedId, content: prompt}));
        dispatch(setPrompt({chatId: selectedId, promptId: id, promptName: title}));
        dispatch(setSelectedChatWindowTab('chat'));
    }

    return (
        <Card className={styles.card} onClick={onClick}>
            <CardHeader
                header={<Text weight="semibold">{title}</Text>}
                description={
                    <Caption1 className={styles.caption}>{tags ? tags.join(", ") : ""}</Caption1>
                }
                action={
                    isPersonal ? (
                        <Button
                            appearance="transparent"
                            icon={<MoreHorizontal20Regular />}
                            aria-label="Edit prompt"
                            onClick={(e) => {
                                e.stopPropagation(); // Prevent card click event
                                onEdit();
                            }}
                        />
                    ) : null
                }
            />
            <p className={styles.text}>
                {description}
            </p>
        </Card>
    )
}
