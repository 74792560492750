import React, { useRef, useState } from "react";
import { Button, makeStyles, shorthands, tokens, Text } from "@fluentui/react-components";
import { AttachRegular, DocumentRegular, DismissRegular } from "@fluentui/react-icons";

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
    },
    dropzone: {
        boxSizing: 'border-box',
        ...shorthands.border('2px', 'dashed', tokens.colorNeutralStroke1),
        ...shorthands.borderRadius('4px'),
        ...shorthands.padding('20px'),
        width: '100%',
        height: '300px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: '20px',
        overflowY: 'auto',
        scrollbarWidth: 'thin',
        scrollbarColor: `${tokens.colorNeutralStroke1} transparent`,
        '&::-webkit-scrollbar': {
            width: '8px',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: tokens.colorNeutralStroke1,
            ...shorthands.borderRadius('4px'),
            ...shorthands.border('2px', 'solid', tokens.colorTransparentStroke),
            backgroundClip: 'content-box',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: tokens.colorNeutralStroke1Hover,
        },
    },
    activeDropzone: {
        backgroundColor: tokens.colorSubtleBackgroundHover,
    },
    fileList: {
        width: '100%',
        marginTop: '10px',
    },
    fileItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '5px',
        ...shorthands.padding('5px'),
        ...shorthands.borderRadius('4px'),
        backgroundColor: tokens.colorNeutralBackground1,
    },
    fileInfo: {
        display: 'flex',
        alignItems: 'center',
        maxWidth: 'calc(100% - 24px)',
        ...shorthands.overflow('hidden'),
    },
    fileName: {
        whiteSpace: 'nowrap',
        ...shorthands.overflow('hidden'),
        textOverflow: 'ellipsis',
    },
    fileIcon: {
        marginRight: '5px',
        flexShrink: 0,
    },
    removeButton: {
        cursor: 'pointer',
        color: tokens.colorNeutralForeground3,
        '&:hover': {
            color: tokens.colorNeutralForeground1,
        },
        flexShrink: 0,
    },
    dropzoneText: {
        textAlign: 'center',
        marginBottom: '10px',
    },
});

interface WorkflowsFileUploaderProps {
    onFilesUploaded: (files: File[]) => void;
    maxWidth?: string | number;
}

export const WorkflowsFileUploader: React.FC<WorkflowsFileUploaderProps> = ({
                                                                                onFilesUploaded,
                                                                                maxWidth = '500px'
                                                                            }) => {
    const styles = useStyles();
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [isDragging, setIsDragging] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files) {
            const newFiles = Array.from(files);
            setUploadedFiles(prevFiles => [...prevFiles, ...newFiles]);
            onFilesUploaded(newFiles);
        }
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = () => {
        setIsDragging(false);
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDragging(false);
        const files = event.dataTransfer.files;
        const newFiles = Array.from(files);
        setUploadedFiles(prevFiles => [...prevFiles, ...newFiles]);
        onFilesUploaded(newFiles);
    };

    const handleRemoveFile = (indexToRemove: number) => {
        setUploadedFiles(prevFiles => prevFiles.filter((_, index) => index !== indexToRemove));
    };

    return (
        <div className={styles.container} style={{ maxWidth }}>
            <div
                className={`${styles.dropzone} ${isDragging ? styles.activeDropzone : ''}`}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
            >
                <Text className={styles.dropzoneText}>
                    {uploadedFiles.length === 0
                        ? "Drag and drop files here"
                        : "Drag and drop more files here"}
                </Text>
                {uploadedFiles.length > 0 && (
                    <div className={styles.fileList}>
                        {uploadedFiles.map((file, index) => (
                            <div key={index} className={styles.fileItem}>
                                <div className={styles.fileInfo}>
                                    <DocumentRegular className={styles.fileIcon} />
                                    <Text className={styles.fileName}>{file.name}</Text>
                                </div>
                                <DismissRegular
                                    className={styles.removeButton}
                                    onClick={() => {handleRemoveFile(index)}}
                                />
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <Button
                appearance="primary"
                icon={<AttachRegular />}
                onClick={() => fileInputRef.current?.click()}
                title="Attach file"
                aria-label="Attach file button"
            >
                Upload Documents
            </Button>
            <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                accept=".txt,.pdf,.docx,.eml,.msg"
                multiple={true}
                onChange={handleFileChange}
            />
        </div>
    );
};
