import { FC, useCallback, useState } from "react";
import { IUserWorkflow } from "../../libs/models/Workflows/UserWorkflow";
import { IUserWorkflowCapture } from "../../libs/models/Workflows/UserWorkflowCapture";
import { Button, Combobox, ComboboxProps, Input, Label, makeStyles, Option, shorthands, Spinner, tokens } from "@fluentui/react-components";
import { WorkflowsFileUploader } from "./WorkflowsFileUploader";
import { debounce } from "lodash";
import { IGraphChatUser } from "../../libs/models/GraphChatUser";
import { useChat } from "../../libs/hooks";
import { Dismiss12Regular } from "@fluentui/react-icons";
import { IUserRequestNewWorkflowInstance } from "../../libs/models/Workflows/UserRequestNewWorkflowInstance";
import { RootState } from "../../redux/app/store";
import { useAppSelector } from "../../redux/app/hooks";
import { useWorkflow } from "../../libs/hooks/useWorkflow";
import { IUserRequestWorkflowInstanceFiles } from "../../libs/models/Workflows/UserRequestWorkflowInstanceFiles";
import { useCustomToast } from "../../libs/hooks/useToast";

const useStyles = makeStyles({
    container: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridTemplateRows: '1fr 1fr',
        ...shorthands.gap(tokens.spacingHorizontalL),
        ...shorthands.padding(tokens.spacingHorizontalL),
        height: '100%',
        ...shorthands.overflow('auto'),
    },
    formSection: {
        ...shorthands.gridArea('1', '1', '2', '2'),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    usersSection: {
        ...shorthands.gridArea('2', '1', '3', '2'),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    fileUploaderSection: {
        ...shorthands.gridArea('1', '2', '2', '3'),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    submitSection: {
        ...shorthands.gridArea('2', '2', '3', '3'),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.gap(tokens.spacingVerticalM),
        width: '100%',
        maxWidth: '400px',
    },
    inputGroup: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.gap(tokens.spacingVerticalS),
        width: '100%',
    },
    label: {
        fontWeight: 'bold',
    },
    input: {
        width: '100%',
    },
    userList: {
        listStyleType: 'none',
        ...shorthands.padding(0),
        ...shorthands.margin(0),
        width: '100%',
        maxWidth: '400px',
    },
    userItem: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        ...shorthands.padding(tokens.spacingVerticalS),
        marginBottom: tokens.spacingVerticalXS,
        backgroundColor: tokens.colorNeutralBackground1,
    },
    removeButton: {
        minWidth: 'auto',
        ...shorthands.padding(tokens.spacingHorizontalS),
    },
    combobox: {
        width: '100%',
        maxWidth: '400px',
    },
    submitButton: {
        minWidth: '200px',
    },
    tagsList: {
        listStyleType: "none",
        marginBottom: tokens.spacingVerticalXXS,
        marginTop: 0,
        paddingLeft: 0,
        display: "flex",
        flexWrap: "wrap",
        gridGap: tokens.spacingHorizontalXXS,
    },
    selectedUserButton: {
        backgroundColor: tokens.colorNeutralBackground1,
        color: tokens.colorNeutralForeground1,
        ...shorthands.borderRadius(tokens.borderRadiusMedium),
        ...shorthands.border('1px', 'solid', tokens.colorNeutralStroke1),
        ...shorthands.padding(tokens.spacingVerticalXS, tokens.spacingHorizontalS),
        display: 'flex',
        alignItems: 'center',
        ...shorthands.gap(tokens.spacingHorizontalXS),
    },
    workflowNameInput: {
        marginBottom: tokens.spacingVerticalM,
    }
});

/* eslint-disable */
const debounceSearchUsers = debounce((searchTerm: string, chat: any, setSearchResults: (results: IGraphChatUser[]) => void) => {
    chat.searchUsers(searchTerm).then((results: IGraphChatUser[]) => {
        setSearchResults(results);
    }).catch((error: any) => {
        console.error('Error searching users:', error);
    });
}, 300);

/* eslint-enable */

interface AddWorkflowPageProps {
    workflow: IUserWorkflow | undefined;
    onSubmit: () => void;
}

export const AddWorkflowPage: FC<AddWorkflowPageProps> = ({workflow, onSubmit}) => {
    const styles = useStyles();
    const chat = useChat();
    const workflowHook = useWorkflow();
    const {showToast} = useCustomToast();
    const {activeUserInfo} = useAppSelector((state: RootState) => state.app);
    const [formData, setFormData] = useState<Record<string, string>>({});
    const [searchResults, setSearchResults] = useState<IGraphChatUser[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [selectedUsers, setSelectedUsers] = useState<IGraphChatUser[]>([]);
    const [files, setFiles] = useState<File[]>([]);
    const [workflowName, setWorkflowName] = useState<string>('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleInputChange = (key: string, value: string) => {
        setFormData((prevData) => ({
            ...prevData,
            [key]: value,
        }));
    };

    const handleSubmit = async () => {
        if (workflow && activeUserInfo) {
            setIsSubmitting(true);
            try {
                const userId = activeUserInfo.id.split('.')[0];
                const users = [...selectedUsers.map(user => user.id), userId];

                const request: IUserRequestNewWorkflowInstance = {
                    workflowId: workflow.workflowId,
                    userInput: formData,
                    users: users,
                    owner: userId,
                    instanceName: workflowName || `${workflow.name} - ${new Date().toLocaleString()}`,
                    instanceDescription: `Created by ${activeUserInfo.username}`,
                };

                const workflowInstance = await workflowHook.addWorkflowInstance(request);

                const fileRequest: IUserRequestWorkflowInstanceFiles = {
                    workflowInstanceId: workflowInstance.workflowInstanceId,
                    workflowInstanceFiles: files
                };

                await workflowHook.addInputUserRequestWorkflowInstanceFiles(fileRequest);
                showToast('Workflow Created Successfully', [`Name: ${request.instanceName}`, `Type: ${workflow.name}`], 'success');
                onSubmit();

            } catch (error) {
                console.error('Error creating workflow instance:', error);
                showToast('Error Creating Workflow', ['An error occurred while creating the workflow.'], 'error');
            } finally {
                setIsSubmitting(false);
            }
        }
    };

    const handleFiles = (files: File[]) => {
        setFiles(files);
    };

    const handleSearch = useCallback((searchTerm: string) => {
        setSearchTerm(searchTerm);
        if (searchTerm.trim()) {
            debounceSearchUsers(searchTerm.trim(), chat, setSearchResults);
        }
    }, [chat]);

    const onSelect: ComboboxProps["onOptionSelect"] = (_event, data) => {
        const foundUser = searchResults.find((user) => user.id === data.optionValue);
        if (foundUser) {
            setSelectedUsers(prev =>
                prev.some(user => user.id === foundUser.id)
                    ? prev.filter(user => user.id !== foundUser.id)
                    : [...prev, foundUser]
            );
            setSearchTerm('');
            setSearchResults([]);
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.formSection}>
                <h2>Add {workflow?.name ?? 'Workflow'}</h2>
                <div className={styles.form}>
                    <div className={styles.inputGroup}>
                        <div className={styles.workflowNameInput}>
                            <Label htmlFor="workflowName" className={styles.label}>Workflow Name</Label>
                            <Input
                                id="workflowName"
                                placeholder="Enter a name for the workflow instance"
                                value={workflowName}
                                onChange={(e) => {
                                    setWorkflowName(e.target.value)
                                }}
                                className={styles.input}
                            />
                        </div>
                    </div>
                </div>
                <form className={styles.form}>
                    {workflow ? (
                        workflow.userCaptures.map((capture: IUserWorkflowCapture) => (
                            <div key={capture.key} className={styles.inputGroup}>
                                <Label htmlFor={capture.key} className={styles.label}>{capture.name}</Label>
                                <Input
                                    id={capture.key}
                                    placeholder={capture.description}
                                    value={formData[capture.key] || ''}
                                    onChange={(e) => {
                                        handleInputChange(capture.key, e.target.value)
                                    }}
                                    maxLength={capture.maxLength}
                                    pattern={capture.regEx}
                                    className={styles.input}
                                />
                            </div>
                        ))
                    ) : (
                        <div>Error: No workflow selected</div>
                    )}
                </form>
            </div>

            <div className={styles.usersSection}>
                <h3>Invited Users</h3>
                <ul className={styles.tagsList}>
                    {selectedUsers.map((user) => (
                        <li key={user.id}>
                            <Button
                                size="small"
                                className={styles.selectedUserButton}
                                icon={<Dismiss12Regular/>}
                                iconPosition="after"
                                onClick={() => {
                                    setSelectedUsers(prev => prev.filter(u => u.id !== user.id))
                                }}
                            >
                                {user.name}
                            </Button>
                        </li>
                    ))}
                </ul>
                <Combobox
                    className={styles.combobox}
                    placeholder="Search for users to invite"
                    onInput={(event) => {
                        handleSearch(event.currentTarget.value)
                    }}
                    value={searchTerm}
                    onOptionSelect={onSelect}
                >
                    {searchResults.map((user) => (
                        <Option key={user.id} value={user.id}>
                            {user.name}
                        </Option>
                    ))}
                </Combobox>
            </div>

            <div className={styles.fileUploaderSection}>
                <WorkflowsFileUploader maxWidth={'700px'} onFilesUploaded={handleFiles}/>
            </div>

            <div className={styles.submitSection}>
                <Button
                    appearance="primary"
                    className={styles.submitButton}
                    onClick={() => {
                        void handleSubmit();
                    }}
                    disabled={isSubmitting}
                >
                    {isSubmitting ? <Spinner size="tiny"/> : 'Create Workflow'}
                </Button>
            </div>
        </div>
    );
};
